import { FC, Children, ReactElement } from "react";
import Link, { LinkProps } from "next/link";
import classnames from "classnames";
import { usePathnameWithLocal } from "@finbackoffice/site-core";

interface IProps {
    children: ReactElement<any>;
    activeClassName: string;
    includes?: string;
    forceActive?: boolean;
}

const ActiveLink: FC<IProps & LinkProps & { className?: string }> = ({
    children,
    activeClassName,
    includes,
    forceActive,
    href,
    as,
    className,
    ...props
}) => {
    const { fullPath } = usePathnameWithLocal();
    const child = Children.only(children);

    const isActive: boolean =
        forceActive ||
        (includes && fullPath?.includes(includes)) ||
        fullPath === as ||
        fullPath === (typeof href === "string" ? href : href.pathname);

    return (
        <Link
            href={href}
            className={classnames(className, isActive && ["activeLink", activeClassName])}
            {...props}>
            {child}
        </Link>
    );
};

export default ActiveLink;
