"use client";

import classNames from "classnames";
import {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useRef,
    ReactElement,
    cloneElement,
} from "react";
import styles from "./fade-in-animation.module.sass";

export type FadeInAnimationVariantType =
    | "opacity"
    | "right"
    | "left"
    | "rightFullPage"
    | "bottom"
    | "height";

export interface IFadeInAnimationProps {
    children: ReactElement<any>;
    variant?: FadeInAnimationVariantType;
    height?: number;
}

type FadeInAnimationHandle = {
    clearVisible: () => void;
    forceOpen: () => void;
};

const FadeInAnimation = forwardRef<FadeInAnimationHandle, IFadeInAnimationProps>(
    ({ children, variant = "opacity", height }, ref) => {
        const [visible, setVisible] = useState<boolean>(false);
        const animationId = useRef<number>(undefined);

        useEffect(() => {
            animationId.current = requestAnimationFrame(() => {
                setVisible(true);
            });

            return () => {
                if (animationId.current) {
                    cancelAnimationFrame(animationId.current);
                }
            };
        }, []);

        useImperativeHandle(ref, () => ({
            clearVisible: () => setVisible(false),
            forceOpen: () => setVisible(true),
        }));

        const heightStyles =
            variant === "height" && height
                ? visible
                    ? { height: visible ? height : 0 }
                    : undefined
                : undefined;

        return cloneElement(children, {
            style: { ...children.props.style, ...heightStyles },
            className: classNames(
                children?.props?.className,
                styles["not-visible"],
                visible && styles.visible,
                styles[variant],
            ),
        });
    },
);

export default FadeInAnimation;
